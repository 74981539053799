<template>

  <nav id="main-navbar" class="sticky-top navbar navbar-expand-sm navbar-light bg-white">

    <div class="container-fluid custom-nav-px" id="navbar"
         style="margin: 0 auto; max-width: 1920px;">

      <a class="navbar-brand m-0 p-0" @click="goToMenu">
        <img src="@/assets/a2.png"
             style="height: 40px;" alt="logo" id="logo">
      </a>

      <a class="navbar-brand m-0 p-0 fw-bold" @click="goToMenu"
         id="nav-text"
         style="line-height: 48px;">
        La Gran Muralla Oriental
      </a>

    </div>
  </nav>

</template>

<script>
import {useRouter} from "vue-router"
import {useStore} from "vuex";
import {computed} from "vue";

export default {
  name: "NavBar",
  setup() {
    const router = useRouter()
    const store = useStore()
    const cart = computed(() => store.getters['cartModule/getItems']())

    window.onscroll = function () {
      scrollFunction()
    };

    function scrollFunction() {
      if (document.body.scrollTop > 71 || document.documentElement.scrollTop > 71) {
        document.getElementById("navbar").style.justifyContent = "space-between";
        document.getElementById("logo").style.display = "inline";
        document.getElementById("main-navbar").style.borderBottom = "1px solid lightgrey";
        document.getElementById("main-navbar").style.padding = "8px 0";
        document.getElementById("main-navbar").style.boxShadow = "0px 5px 30px -15px rgba(0,0,0,0.75)";
        document.getElementById("nav-text").style.fontSize = "1.25rem";

      } else {
        document.getElementById("navbar").style.justifyContent = "space-between";
        document.getElementById("logo").style.display = "inline";
        document.getElementById("main-navbar").style.borderBottom = "0";
        document.getElementById("main-navbar").style.padding = "8px 0";
        document.getElementById("main-navbar").style.boxShadow = "none";
        document.getElementById("nav-text").style.fontSize = "1.25rem";

      }
    }

    return {
      cart,
      total_items: computed(() => {
        if (cart.value.length === 0) return 0
        else {
          return cart.value.map((a) => a.quantity).reduce((prev, next) => prev + next)
        }
      }),
      goToHome: () => router.push({name: 'home'}),
      goToMenu: () => router.push({
        name: 'item',
        query: {table_id: router.currentRoute.value.query.table_id}
      }),
      isGrayBackground: computed(() => {
        return router.currentRoute.value.name === 'cart'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import url("../../../assets/animate.min.css");

.nav-hover :hover {
  background-color: #8BD3E6;
}

#nav {
  padding: 30px;
  text-align: center;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

.display-none {
  display: none !important;
}

@media (min-width: 768px) {
  .md-display {
    display: flex;
  }
  .md-fs-4 {
    font-size: calc(1.275rem + 0.3vw) !important;
  }


}

@media (max-width: 576px) {
  .sm-none {
    display: none;
  }

}

@media (max-width: 768px) {
  .md-none {
    display: none;
  }
  .md-mr-1 {
    margin-right: 0.25rem
  }


}

@media (max-width: 992px) {
  .lg-none {
    display: none;
  }
  .custom-nav-px {
    padding: 0 1rem !important;
  }
}

@media (min-width: 991px) {
  .custom-nav-px {
    padding: 0 3rem !important;
  }
}

.btm-shadow {
  box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);
}


</style>
